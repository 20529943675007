/* eslint no-use-before-define: 0 */
@import "bootstrap/scss/bootstrap";
@import "helpers";
@import "components";
@import "react-simple-keyboard/build/css/index.css";
@import "rsuite/dist/rsuite.min.css";

@font-face {
  font-family: Poppins;
  src: url("../assets/fonts/Poppins-Thin.ttf");
}

@font-face {
  font-family: Poppins;
  src: url("../assets/fonts/Poppins-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Poppins;
  src: url("../assets/fonts/Poppins-SemiBold.ttf");
  font-weight: bolder;
}

@font-face {
  font-family: Poppins;
  src: url("../assets/fonts/Poppins-ExtraLight.ttf");
  font-weight: 300;
}

@font-face {
  font-family: Poppins;
  src: url("../assets/fonts/Poppins-Light.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Poppins;
  src: url("../assets/fonts/Poppins-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Poppins;
  src: url("../assets/fonts/Poppins-Regular.ttf");
  font-weight: 600;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Poppins;
}

#root {
  height: 100%;
  overflow-y: hidden;
}

.color-black {
  color: #000;
}

.color-blue {
  color: #0060e8;
}

.background-color-base {
  background-color: #f0f6f6;
}

.background-color-gris {
  background-color: #fafbfd;
}

.background-color-white {
  background-color: #fff;
}
