.sign-in-component {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    transition: 0.2s ease;
    z-index: 1000;
    display: flex;
    justify-content: flex-end;
    background-color: rgba(0, 0, 0, 0.298);

    &__form {
        background-color: #fff;
    }
}
